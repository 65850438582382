/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";

interface ComponentProps {
  label?: string;
  links: Array<{
    label: string;
    url: string;
  }>;
}

const LinksCTA = ({ label, links }: ComponentProps) => (
  <div
    sx={{
      bg: "stream",
      display: ["block", "inline-block"],
      px: rem(20),
      py: rem(16),
    }}
  >
    {label && (
      <h2
        sx={{
          fontSize: rem(20),
          mr: rem(20),
          my: 0,
          display: ["block", "inline-block"],
          textAlign: ["center", "initial"],
        }}
      >
        {label}
      </h2>
    )}

    <div
      sx={{
        display: ["block", "inline-block"],
        textAlign: ["center", "initial"],
      }}
    >
      {links.map(({ label, url }) => (
        <a
          href={url}
          sx={{
            fontFamily: "heading",
            color: "coal",
            variant: "typography.link",
            mr: rem(20),
            "&:last-child": {
              mr: 0,
            },
          }}
        >
          {label}
        </a>
      ))}
    </div>
  </div>
);

export default LinksCTA;
