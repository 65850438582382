import striptags from "striptags";
const mapCategory = (category) => {
  return {
    title: category.title,
    description: category.description,
    heroImage: category.image[0] || null,
    links: category.links.map(({ linkUrl, ...link }) => ({
      ...link,
      url: linkUrl,
    })),
  };
};

const mapRelatedArticles = (articles, tag) => {
  return articles
    .filter((article) => {
      if (
        article.tags.filter(({ slug }) => slug === tag.slug).length ||
        article.primaryTopic.filter(({ slug }) => slug === tag.slug).length ||
        article.contentType.filter(({ slug }) => slug === tag.slug).length ||
        article.wordsBy.filter(({ slug }) => slug === tag.slug).length ||
        article.imagesBy.filter(({ slug }) => slug === tag.slug).length
      ) {
        return true;
      }

      return false;
    })

    .map((article) => ({
      title: article.title,
      img: article.thumbnail[0]
        ? article.thumbnail[0].url
        : article.heroImage[0]
        ? article.heroImage[0].url
        : null,
      introText: article.introductoryText
        ? striptags(article.introductoryText)
        : null,
      url: `/articles/${article.slug}`,
      tagDuo: [article.contentType[0].title, article.primaryTopic[0].title],
    }));
};

const mapContributors = (tags) => {
  return tags
    .filter((tag) => tag.tagType === "contributor")
    .filter((tag) => tag.showOnContributorsPage)

    .map((tag) => ({
      title: tag.title,
      img: tag.image[0] ? tag.image[0].url : null,
      introText: tag.contributorExcerpt
        ? striptags(tag.contributorExcerpt)
        : striptags(tag.description),
      url: `/tags/${tag.slug}`,
    }));
};

export const mapCraft = (craft) => {
  return {
    category: mapCategory(craft.category),
    relatedArticles:
      craft.category.title === "Contributors"
        ? mapContributors(craft.allCategories)
        : mapRelatedArticles(craft.articles, craft.category).reverse(),
  };
};
