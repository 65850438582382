/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import SEO from "@components/seo";
import Layout from "@components/layouts";
import { Grid, Cell } from "@components/primitives/Grid";
import { rem } from "@src/theme";
import LinksCTA from "@components/LinksCTA";
import ArticleListingGrid from "@components/ArticleListingGrid";
import { graphql } from "gatsby";
import { mapCraft } from "./mappings";
import React from "react";
import striptags from "striptags";
import articleStyles from "@src/global-styles/article";

export default ({ location, ...props }) => {
  const { category, relatedArticles } = mapCraft(props.data.craft);
  const strippedDescription = striptags(category.description);
  return (
    <Layout location={location}>
      <SEO
        location={location}
        defaultTitle={category.title}
        metadata={{
          metaDescription: strippedDescription,
          socialImage: category.heroImage ? category.heroImage.url : "",
        }}
      />

      <section>
        <Grid>
          <Cell gridColumn={["1 / -1", null, "2 / span 8"]}>
            {category.heroImage && (
              <img
                src={category.heroImage.url}
                sx={{
                  display: ["block", null, "none"],
                  width: "100%",
                }}
              ></img>
            )}

            <Styled.h1
              sx={{
                variant: "typography.headingLarge",
                mb: rem(36),
                mt: rem(76),
              }}
            >
              {category.title}
            </Styled.h1>

            <div
              sx={{
                mb: 70,
                "> :first-child": {
                  mt: 0,
                },
                "> :last-child": {
                  mb: 0,
                },
                variant: "typography.bodyLarge",
                strong: {
                  fontWeight: "bold",
                },
                "h1, h2": {
                  fontSize: "1.92rem!important", //24
                },
                "h1,h2,h3,h4,h5": {
                  fontFamily: "heading",
                  lineHeight: 1.1,
                  mt: 0,
                  mb: rem(10),
                },
                h3: { fontSize: "1.6rem" },
                h4: { fontSize: "1.4rem" },
                h5: { fontSize: "1rem", textTransform: "uppercase" },
              }}
              dangerouslySetInnerHTML={{ __html: category.description }}
            ></div>
            {category.links.length ? <LinksCTA links={category.links} /> : null}

            <div
              sx={{
                display: ["block", "none"],
                "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
                  display: "block",
                },
                mb: rem(40),
              }}
            ></div>
          </Cell>
          {category.heroImage && (
            <Cell
              gridColumn={["1 / -1", "11 / span 6"]}
              styles={{
                display: ["none", null, "block"],
              }}
            >
              <img
                src={category.heroImage.url}
                sx={{
                  width: "100%",
                }}
              ></img>
            </Cell>
          )}
          {relatedArticles.length ? (
            <React.Fragment>
              <Cell
                styles={{
                  height: rem(56),
                }}
              />

              <Cell gridColumn={["1 / -1", null, "2 / -1"]}>
                <ArticleListingGrid
                  items={relatedArticles}
                  contributorMode={category.title === "Contributors"}
                />
              </Cell>
            </React.Fragment>
          ) : null}
        </Grid>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query TagQuery($id: Craft_QueryArgument) {
    craft {
      category(id: [$id]) {
        ...tagFields
      }

      allCategories: categories {
        ... on Craft_tags_Category {
          id

          title
          slug
          image {
            url
          }
          description
          tagType
          showOnContributorsPage
          contributorExcerpt
        }
      }

      articles: entries(section: "articles", orderBy: "date") {
        ...articleGridFields
      }
    }
  }
`;
